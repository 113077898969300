/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.page-home {
  --navbar-height: 160px;
}
@media (max-width: 767.98px) {
  html.page-home {
    --navbar-height: 135px;
  }
}
html.page-home body {
  padding-top: 0;
}

.p--home {
  position: relative;
}

.p-home__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0px;
  left: 0;
  z-index: -1;
}

.p-home__title {
  font-weight: 700;
  padding-bottom: 54px;
}

.p-home__title-item--1,
.p-home__title-item--2 {
  text-transform: uppercase;
}

.p-home__title-item--1,
.p-home__title-item--3 {
  font-size: 60px;
  line-height: 100%;
}

.p-home__title-item--2,
.p-home__title-item--3 {
  color: #fff;
}

.p-home__title-item--1 {
  color: var(--color-lightbrown);
  letter-spacing: -4px;
}

.p-home__title-item--2 {
  font-size: 30px;
  line-height: 100%;
}

.p-home__title-item--3 {
  margin-left: 8px;
  font-style: italic;
}

.p-home__title-row--2 {
  margin-top: 0.5em;
}

.p-home__ctas .w-ctas__cta-readmorecontainer,
.p-home__blogs .w-ctas__cta-readmorecontainer {
  transition: width 0.5s;
  width: 0%;
  white-space: nowrap;
}
@media (hover: hover) {
  .p-home__ctas .w-ctas__cta-readmorecontainer:hover,
  .p-home__blogs .w-ctas__cta-readmorecontainer:hover {
    width: 100%;
  }
}
.p-home__ctas .w-ctas__text,
.p-home__blogs .w-ctas__text {
  padding: 1rem 0 0 0;
}
.p-home__ctas .w-ctas .btn-primary,
.p-home__blogs .w-ctas .btn-primary {
  --bs-btn-bg: none;
  --bs-btn-border-width: 0;
  --bs-btn-color: var(--bs-primary);
  --bs-btn-border-radius: 0;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-color: var(--bs-secondaryy);
  text-align: left;
  padding: 0 0 0.5rem;
  font-family: "cera-pro-medium";
  border-bottom: 1px solid var(--bs-primary);
  min-width: 0;
  min-width: 100%;
  margin-top: 16px;
}
@media (hover: hover) {
  .p-home__ctas .w-ctas .btn-primary:hover,
  .p-home__blogs .w-ctas .btn-primary:hover {
    min-width: 100%;
  }
}
.p-home__ctas .w-ctas__cta-title,
.p-home__blogs .w-ctas__cta-title {
  font-family: "fauna-one";
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: var(--bs-primary);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-home__ctas .w-ctas--blockstype-textbelow .w-ctas__text,
.p-home__ctas .w-ctas--blockstype-bgcolor .w-ctas__text,
.p-home__blogs .w-ctas--blockstype-textbelow .w-ctas__text,
.p-home__blogs .w-ctas--blockstype-bgcolor .w-ctas__text {
  background-color: transparent;
}
.p-home__ctas .w-ctas__image,
.p-home__blogs .w-ctas__image {
  border-radius: 6px;
}

.p-home__ctas {
  padding-top: 100px;
}
.p-home__ctas .w-ctas {
  --ww-widget-ctas-image-aspect-ratio: 100%;
  --ww-widget-ctas-image-border-radius: 0;
}

.p-home__blogs .w-ctas {
  --ww-widget-ctas-image-aspect-ratio: 70%;
  --ww-widget-ctas-image-border-radius: 0;
}
.p-home__blogs .w-ctas__blocktitle {
  margin-bottom: 2rem;
}
.p-home__blogs .w-ctas__cta-subtitle {
  color: var(--bs-secondary);
}
.p-home__blogs .w-ctas__imagelinkcontainer {
  position: relative;
}
.p-home__blogs .c-blogger {
  pointer-events: none;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
}
.p-home__blogs .c-blogger .c-blogger__image {
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  overflow: hidden;
  border: 2px solid #fff;
  border-radius: 2.5rem;
}
.p-home__blogs .c-blogger .c-blogger__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.p-home__ctas-inner {
  position: relative;
}
.p-home__ctas-inner:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-lightgray);
  z-index: -1;
}

.p-home__title-selectcategory {
  padding-top: var(--navbar-height);
  padding-bottom: 100px;
}

#p-home-ctas .w-ctas__blocktitle {
  margin-bottom: 2rem;
}
#p-home-ctas .w-ctas__blocktitle .c-blocktitle__row1 span:nth-child(1) {
  font-weight: 700;
  font-size: 52px;
  line-height: 100%;
  color: var(--bs-primary);
}
#p-home-ctas .w-ctas__blocktitle .c-blocktitle__row1 span:nth-child(2) {
  font-family: "cera-pro-black", Georgia, "Times New Roman", serif;
  text-transform: uppercase;
  font-size: 52px;
  line-height: 100%;
  color: var(--bs-secondary);
  font-weight: 400;
}
#p-home-ctas .w-ctas__blocktitle .c-blocktitle__row2 {
  font-size: 20px;
  line-height: 100%;
  color: var(--bs-primary);
  text-transform: uppercase;
}

.p-home__title-selectcategory {
  position: relative;
}

.p-home__bottomtext {
  max-width: 1000px;
}
@media (min-width: 768px) {
  .p-home__bottomtext .w-photoalbum__items--grid {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
  .p-home__bottomtext .w-textimage__text {
    position: relative;
  }
  .p-home__bottomtext .widget--photoalbum {
    position: absolute;
    bottom: -120px;
    left: 0;
    z-index: 10;
    left: -90px;
    right: -90px;
  }
}
.p-home__bottomtext .p-home__title-item--2,
.p-home__bottomtext .p-home__title-item--3 {
  color: var(--bs-primary);
}

@media (min-width: 768px) {
  .p-home__title-item--2,
  .p-home__title-item--3 {
    display: inline;
    transform: translateX(120px);
  }
  .p-home__title-item--1 {
    font-size: 85px;
    line-height: 100%;
    font-weight: 500;
  }
  .p-home__title-item--2 {
    font-size: 35px;
    line-height: 100%;
  }
  .p-home__title-item--3 {
    font-size: 80px;
    line-height: 100%;
  }
}