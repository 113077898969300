/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-categoryslider {
  padding: 52px 0;
  margin: 100px 0;
  background-color: #edf0f1;
}
.w-categoryslider .splide__slide img {
  width: 100%;
  height: auto;
}
.w-categoryslider .splide__slide {
  opacity: 0.6;
  opacity: 1;
  color: var(--bs-primary);
}
.w-categoryslider .splide__slide.is-active {
  opacity: 1;
  border: 0 !important;
  color: var(--color-red);
}
.w-categoryslider .w-textimage {
  --ww-widget-textimage-text-align-items: flex-start;
}
@media (max-width: 767.98px) {
  .w-categoryslider .w-textimage .w-textimage__imagecontainer {
    display: none;
  }
}
.w-categoryslider .w-textimage .w-textimage__textcontainer {
  color: var(--bs-primary);
}

.w-categoryslider__scroller {
  max-width: 920px;
  margin: 3rem auto;
}

.w-categoryslider__slide-title {
  color: var(--color-red);
  text-transform: uppercase;
  font-size: 16px;
  line-height: 100%;
}

.w-categoryslider__title {
  font-size: 32px;
  line-height: 40px;
  color: var(--bs-primary);
  text-transform: uppercase;
  max-width: 420px;
  margin: 0 auto;
  text-align: center;
}

.skull-slider-wrapper {
  visibility: hidden;
  visibility: visible;
  margin: 0 auto;
  width: 100%;
  height: 40px;
  position: relative;
}
.skull-slider-wrapper .skull-start-point,
.skull-slider-wrapper .skull-end-point {
  position: absolute;
  width: 2px;
  height: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background: var(--bs-primary);
}
.skull-slider-wrapper .skull-start-point {
  left: 0px;
}
.skull-slider-wrapper .skull-end-point {
  right: 0px;
}
.skull-slider-wrapper .skull-rail {
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  margin-top: -1px;
  border-top: 3px dotted var(--bs-primary);
}
.skull-slider-wrapper #skull-object {
  position: absolute;
  top: calc(-50% + 10px);
  z-index: 2;
}

#skull-object {
  width: 80px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#skull {
  width: 43px;
  height: 37px;
}