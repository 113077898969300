/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-textimage__textcontainer, .w-textimage__imagecontainer {
  display: flex;
}
.w-textimage__textcontainer {
  order: 1;
  align-items: var(--ww-widget-textimage-text-align-items);
}
.w-textimage__divider {
  min-height: 2rem;
}
.w-textimage__text > *:first-child {
  margin-top: 0;
}
.w-textimage__text > *:last-child {
  margin-bottom: 0;
}
.w-textimage__imagecontainer {
  order: 3;
  align-items: var(--ww-widget-textimage-image-align-items);
}
.w-textimage__divider {
  order: 2;
}
.w-textimage--imageleft .w-textimage__container {
  grid-template-columns: var(--ww-widget-textimage-column-image-width) auto var(--ww-widget-textimage-column-text-width);
}
.w-textimage--imageleft .w-textimage__textcontainer {
  order: 3;
}
.w-textimage--imageleft .w-textimage__imagecontainer {
  order: 1;
}
.w-textimage__image {
  max-width: 100%;
  border-radius: var(--ww-widget-textimage-border-radius);
  display: block;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .w-textimage__container {
    display: grid;
    grid-template-columns: var(--ww-widget-textimage-column-text-width) auto var(--ww-widget-textimage-column-image-width);
  }
}

.w-tit-textimage .w-textimage__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
@media (min-width: 768px) {
  .w-tit-textimage .w-textimage__container {
    display: grid;
    grid-template-columns: var(--ww-widget-textimage-column-text-width) var(--ww-widget-textimage-column-image-width);
    gap: 4rem;
  }
}
.w-tit-textimage .w-textimage.w-textimage--imageleft .w-textimage__container {
  grid-template-columns: var(--ww-widget-textimage-column-image-width) var(--ww-widget-textimage-column-text-width);
}
.w-tit-textimage .w-textimage--imagetop {
  background: var(--color-lightgray);
}
.w-tit-textimage .w-textimage--imagetop .w-textimage__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
}
.w-tit-textimage .w-textimage--imagetop .w-textimage__imagecontainer {
  order: -1;
  max-width: 90%;
  transform: translateY(-60px);
}
.w-tit-textimage .w-textimage--imagetop .w-textimage__textcontainer {
  padding: 0 8rem 3rem;
  text-align: center;
}
.w-tit-textimage .w-textimage--imagetop .w-textimage__textcontainer p {
  line-height: 32px;
}
@media (max-width: 767.98px) {
  .w-tit-textimage .w-textimage--imagetop .w-textimage__textcontainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}