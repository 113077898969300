.footer a:not(.btn) {
  text-decoration: none;
}
@media (hover: hover) {
  .footer a:not(.btn):hover {
    text-decoration: underline;
  }
}

.footer p {
  margin-bottom: 0.5rem;
}
@media (hover: hover) {
  .footer .w-socials__item:hover .w-socials__item-image {
    transform: scale(1.15);
  }
}
.footer .w-socials__item-image {
  width: 24px;
  height: 24px;
  transition: transform 0.3s;
}