@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");
/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
:root {
  /* Widget: CTAs */
  --ww-widget-ctas-image-aspect-ratio: 56.25%;
  /* percentage: (H / B) * 100% (standaard is 9:16) */
  --ww-widget-ctas-image-max-width: 100%;
  --ww-widget-ctas-image-border-radius: 10px;
  --ww-widget-ctas-image-overlay: linear-gradient(rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.8) 100%);
  /* kan ook: rgba(0, 0, 0, 0.5) */
  --ww-widget-ctas-image-overlay-opacity-hover: 0.5;
  --ww-widget-ctas-image-transform-hover: scale(1.1);
  --ww-widget-ctas-grid-gap: 2rem;
  --ww-widget-ctas-col-box-shadow: inherit;
  --ww-widget-ctas-text-padding: 1rem;
  --ww-widget-ctas-text-background-color: #f2f2f5;
  --ww-widget-ctas-text-background-color-hover: #d5d5df;
  --ww-widget-ctas-text-color: #fff;
  --ww-widget-ctas-hasbgimage-padding-y: 3rem;
}

.widget--ctas.widget--hasbgimage {
  padding-top: var(--ww-widget-ctas-hasbgimage-padding-y);
  padding-bottom: var(--ww-widget-ctas-hasbgimage-padding-y);
}

.w-ctas {
  margin: 0 auto;
}
.w-ctas__container {
  display: grid;
  grid-gap: var(--ww-widget-ctas-grid-gap);
}
@media (min-width: 576px) {
  .w-ctas__container--4, .w-ctas__container--3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .w-ctas__container--4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 768px) {
  .w-ctas__container--3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 576px) {
  .w-ctas__container--2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.w-ctas__imagelinkcontainer {
  max-width: var(--ww-widget-ctas-image-max-width);
}
.w-ctas__imagelink {
  display: block;
  height: 0;
  position: relative;
  padding-bottom: var(--ww-widget-ctas-image-aspect-ratio);
}
.w-ctas__image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.w-ctas__text {
  padding: var(--ww-widget-ctas-text-padding);
}
.w-ctas__text > * {
  margin-top: 0;
  margin-bottom: 1em;
}
.w-ctas__text h3 {
  font-size: 1.5rem;
}
.w-ctas__text h4 {
  font-size: 1.125rem;
}
.w-ctas__text .btn {
  margin-top: 1em;
}
.w-ctas__cta-readmore {
  min-width: var(--ww-button-min-width);
}
.w-ctas--blockstype-textbelow .w-ctas__text, .w-ctas--blockstype-bgcolor .w-ctas__text {
  background-color: var(--ww-widget-ctas-text-background-color);
}
.w-ctas--blockstype-bgimage .w-ctas__imagelinkcontainer, .w-ctas--blockstype-bgcolor .w-ctas__imagelinkcontainer {
  max-width: 100% !important;
}
.w-ctas--blockstype-bgimage .w-ctas__col-inner, .w-ctas--blockstype-bgcolor .w-ctas__col-inner {
  position: relative;
  height: calc(100% - 2 * var(--ww-widget-ctas-text-padding));
}
.w-ctas--blockstype-bgimage .w-ctas__imagelinkcontainer, .w-ctas--blockstype-bgcolor .w-ctas__imagelinkcontainer {
  margin-bottom: 0 !important;
  height: 100%;
}
.w-ctas--blockstype-bgimage .w-ctas__text, .w-ctas--blockstype-bgcolor .w-ctas__text {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  pointer-events: none;
}
.w-ctas--blockstype-bgimage .w-ctas__text > *:first-child, .w-ctas--blockstype-bgcolor .w-ctas__text > *:first-child {
  margin-top: 0;
}
.w-ctas--blockstype-bgimage .w-ctas__text > *:last-child, .w-ctas--blockstype-bgcolor .w-ctas__text > *:last-child {
  margin-bottom: 0;
}
.w-ctas--blockstype-bgimage .w-ctas__text .btn, .w-ctas--blockstype-bgcolor .w-ctas__text .btn {
  display: none;
}
.w-ctas--blockstype-bgimage .w-ctas__text .widget--button .btn, .w-ctas--blockstype-bgcolor .w-ctas__text .widget--button .btn {
  display: unset;
}
.w-ctas--blockstype-textbelow .w-ctas__imagelink[href], .w-ctas--blockstype-bgimage .w-ctas__imagelink[href] {
  overflow: hidden;
}
@media (hover: hover) {
  .w-ctas--blockstype-textbelow .w-ctas__imagelink[href]:hover .w-ctas__image, .w-ctas--blockstype-bgimage .w-ctas__imagelink[href]:hover .w-ctas__image {
    transform: var(--ww-widget-ctas-image-transform-hover);
  }
}
.w-ctas--blockstype-textbelow .w-ctas__image, .w-ctas--blockstype-bgimage .w-ctas__image {
  transition: transform 0.3s;
}
.w-ctas--blockstype-textbelow .w-ctas__col {
  border-radius: var(--ww-widget-ctas-image-border-radius);
  box-shadow: var(--ww-widget-ctas-col-box-shadow);
  overflow: hidden;
}
.w-ctas--blockstype-textbelow .w-ctas__col-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.w-ctas--blockstype-textbelow .w-ctas__text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.w-ctas--blockstype-textbelow .w-ctas__cta-readmorecontainer {
  margin-top: auto;
}
.w-ctas--blockstype-bgimage .w-ctas__imagelinkoverlay {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--ww-widget-ctas-image-overlay);
  transition: opacity 0.3s;
}
@media (hover: hover) {
  .w-ctas--blockstype-bgimage .w-ctas__imagelinkoverlay:hover {
    opacity: var(--ww-widget-ctas-image-overlay-opacity-hover);
  }
}
.w-ctas--blockstype-bgimage .w-ctas__text > * {
  color: var(--ww-widget-ctas-text-color);
}
.w-ctas--blockstype-bgimage .w-ctas__imagelink {
  border-radius: var(--ww-widget-ctas-image-border-radius);
}
.w-ctas--blockstype-bgcolor .w-ctas__text {
  justify-content: flex-start;
  text-decoration: none;
  position: static;
  height: auto;
  min-height: 100%;
  color: inherit;
  pointer-events: auto;
  border-radius: var(--ww-widget-ctas-image-border-radius);
  transition: background 0.3s;
}
.w-ctas--blockstype-bgcolor .w-ctas__text p {
  color: rgba(25, 25, 89, 0.6);
}
@media (hover: hover) {
  .w-ctas--blockstype-bgcolor .w-ctas__text:hover {
    background: var(--ww-widget-ctas-text-background-color-hover);
  }
}

.w-ctas--overwrite-aspectratio .w-ctas__imagelink {
  height: auto;
  padding-bottom: 0;
}

.w-ctas__cta-pillcontainer {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 10;
}

.w-ctas__cta-pill {
  background-color: #fff;
  color: #000;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.footer {
  background-color: var(--ww-footer-bg-color);
  color: var(--ww-footer-text-color);
  margin-top: var(--ww-footer-margin-top);
  padding-top: var(--ww-footer-padding-top);
  padding-bottom: var(--ww-footer-padding-bottom);
}
.footer h2, .footer h3, .footer h4 {
  color: var(--ww-footer-heading-color);
}
.footer__cols {
  display: grid;
  grid-gap: var(--ww-footer-grid-gap);
}
@media (min-width: 576px) {
  .footer__cols--4, .footer__cols--3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
  .footer__cols--4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1200px) {
  .footer__cols--3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 576px) {
  .footer__cols--2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.footer__col > *:first-child {
  margin-top: 0;
}
.footer__col > *:last-child {
  margin-bottom: 0;
}
.footer a:not(.btn) {
  color: inherit;
  text-decoration: underline;
}
@media (hover: hover) {
  .footer a:not(.btn):hover {
    text-decoration: none;
  }
}

/* Imports */
.wh-form__imgedit {
  width: 200px;
  height: 200px;
}
.wh-form__imgeditdelete {
  cursor: pointer;
}
.wh-form__imgeditholder::after {
  z-index: 1;
  position: absolute;
  display: none;
  width: 60px;
  top: 50%;
  left: 50%;
  height: 60px;
  font-size: 60px;
  line-height: 60px;
  margin-top: -30px;
  margin-left: -30px;
  opacity: 0.8;
}
.wh-form--uploading .wh-form__imgeditholder::after {
  display: inline-block;
}
.wh-form--uploading .wh-form__imgedit::after,
.wh-form--uploading .wh-form__imgedit .wh-form__imgeditholder::before {
  display: none;
}

.wh-form__textinput, .wh-form__textinput[type=password], .wh-form__uploadfieldfilename, .wh-form__textarea {
  width: 100%;
  font-size: var(--form-textinput-font-size);
  line-height: 100%;
  color: #000;
  font-weight: normal;
  background: #fff;
  border-radius: 4px;
  box-shadow: none;
  padding: 10px;
  margin: 0;
  border: 1px solid #000;
  font-family: inherit;
}
.wh-form__textinput[disabled], .wh-form__textinput[type=password][disabled], .wh-form__uploadfieldfilename[disabled], .wh-form__textarea[disabled] {
  background: var(--ww-form-textinput-disabled-bg-color);
}
.wh-form__textarea {
  min-height: 120px;
}
.wh-form__dateinputgroup {
  max-width: 200px;
}
.wh-form__timeinputgroup {
  max-width: 140px;
}
.wh-form__textinput[type=number] {
  max-width: 200px;
}

.wh-form__uploadfield {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.wh-form__uploadfieldselect, .wh-form__uploadfieldfilename {
  cursor: pointer;
}
.wh-form__uploadfieldselect {
  white-space: nowrap;
  padding: 8px 24px;
  min-height: initial;
}
.wh-form__uploadfieldinputholder {
  flex-grow: 1;
  min-width: 300px;
  max-width: 100%;
}
.wh-form__uploadfieldfilename {
  width: 100%;
}
.wh-form__uploadfielddelete {
  top: 50%;
  transform: translate(-2px, -50%);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  width: 20px;
  line-height: 100%;
  position: absolute;
  right: 0;
}

/* Styling */
.circular,
.circular-light,
.circular-book,
.circular-medium,
.circular-bold,
.circular-black {
  font-family: "Circular", Georgia, "Times New Roman", serif;
}

.circular-light {
  font-weight: 300;
}

.circular-book {
  font-weight: 350;
}

.circular-medium {
  font-weight: 500;
}

.circular-bold {
  font-weight: 700;
}

.circular-black {
  font-weight: 900;
}

.cera-pro,
.cera-pro-black,
.cera-pro-medium {
  font-family: "Cera Pro", Georgia, "Times New Roman", serif !important;
}

.cera-pro-medium {
  font-weight: 500;
}

.cera-pro-black {
  font-weight: 900;
}

@font-face {
  font-family: "Circular";
  font-weight: 300;
  src: url("~@mod-tit/webdesigns/tit/shared/fonts/3760circular-light.woff2") format("woff2"), url("~@mod-tit/webdesigns/tit/shared/fonts/3760circular-light.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Circular";
  font-weight: 350;
  src: url("~@mod-tit/webdesigns/tit/shared/fonts/8703circular-book.woff2") format("woff2"), url("~@mod-tit/webdesigns/tit/shared/fonts/8703circular-book.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Circular";
  font-weight: 500;
  src: url("~@mod-tit/webdesigns/tit/shared/fonts/7056circular-medium.woff2") format("woff2"), url("~@mod-tit/webdesigns/tit/shared/fonts/7056circular-medium.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Circular";
  font-weight: 700;
  src: url("~@mod-tit/webdesigns/tit/shared/fonts/402circular-bold.woff2") format("woff2"), url("~@mod-tit/webdesigns/tit/shared/fonts/402circular-bold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Circular";
  font-weight: 900;
  src: url("~@mod-tit/webdesigns/tit/shared/fonts/8892circular-black.woff2") format("woff2"), url("~@mod-tit/webdesigns/tit/shared/fonts/8892circular-black.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "fauna-one";
  src: url("~@mod-tit/webdesigns/tit/shared/fonts/54fauna-one.woff2") format("woff2"), url("~@mod-tit/webdesigns/tit/shared/fonts/54fauna-one.woff") format("woff");
  font-display: swap;
}
.fauna-one {
  font-family: "fauna-one" !important;
}

@font-face {
  font-family: "Cera Pro";
  font-weight: 400;
  src: url("~@mod-tit/webdesigns/tit/shared/fonts/4243cera-pro-regular.woff2") format("woff2"), url("~@mod-tit/webdesigns/tit/shared/fonts/4243cera-pro-regular.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Cera Pro";
  font-weight: 500;
  src: url("~@mod-tit/webdesigns/tit/shared/fonts/1294cera-pro-medium.woff2") format("woff2"), url("~@mod-tit/webdesigns/tit/shared/fonts/1294cera-pro-medium.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Cera Pro";
  font-weight: 900;
  src: url("~@mod-tit/webdesigns/tit/shared/fonts/9925cera-pro-black.woff2") format("woff2"), url("~@mod-tit/webdesigns/tit/shared/fonts/9925cera-pro-black.woff") format("woff");
  font-display: swap;
}
.font-sacramento {
  font-family: "Sacramento", cursive;
  font-size: 48px;
  line-height: 100%;
}

@font-face {
  font-family: "silent-caroline";
  src: url("~@mod-tit/webdesigns/tit/shared/fonts/8805silent-caroline.woff2") format("woff2"), url("~@mod-tit/webdesigns/tit/shared/fonts/8805silent-caroline.woff") format("woff");
  font-display: swap;
}
.silent-caroline {
  font-family: "silent-caroline" !important;
  font-size: 58px;
  line-height: 100%;
}

@font-face {
  font-family: "averta-bold";
  src: url("~@mod-tit/webdesigns/tit/shared/fonts/7953averta-bold.woff2") format("woff2"), url("~@mod-tit/webdesigns/tit/shared/fonts/7953averta-bold.woff") format("woff");
  font-display: swap;
}
.averta-bold {
  font-family: "averta-bold" !important;
}

/*
  Headings: Circular
  Body: Cera Pro

  Some headings: Athelas (regular or bolditalic); changed to Merriweather due to licensing issues
  Some headings: Fauna One
*/
body {
  -webkit-font-smoothing: antialiased;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  margin-bottom: 0.5em;
  color: var(--bs-primary);
}

h1,
.h1,
h3,
.h3,
h4,
.h4 {
  font-family: "fauna-one";
  font-weight: 500;
}

h1,
.h1 {
  font-size: 35px;
  line-height: 49px;
}

h2,
.h2 {
  font-family: "Sacramento", cursive;
  font-size: 48px;
  line-height: 100%;
  font-size: 48px;
  line-height: 100%;
  font-weight: 400;
}

h3,
.h3 {
  font-size: 25px;
  line-height: 100%;
  line-height: 140%;
}

h4,
.h4 {
  font-size: 22px;
}

.merriweather-spacing {
  letter-spacing: 2px;
}

.merriweather-light {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: normal;
}

.merriweather-regular {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}

.merriweather-bold {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: normal;
}

.merriweather-black {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: normal;
}

.merriweather-light-italic {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: italic;
}

.merriweather-regular-italic {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: italic;
}

.merriweather-bold-italic {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: italic;
}

.merriweather-black-italic {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: italic;
}

.btn-primary {
  --bs-btn-bg: var(--color-lightbrown);
  --bs-btn-border-color: inherit;
  --bs-btn-hover-bg: var(--color-red);
  --bs-btn-active-bg: var(--bs-btn-hover-bg);
  --bs-btn-active-border-color: var(--bs-btn-hover-border-color);
}

.btn-outline-primary {
  --bs-btn-border-color: var(--color-red);
  --bs-btn-color: var(--color-red);
  --bs-btn-hover-bg: var(--color-red);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-border-color: var(--color-red);
  --bs-btn-active-bg: var(--bs-btn-hover-bg);
  --bs-btn-active-border-color: var(--bs-btn-hover-border-color);
}

.navbar {
  --bs-navbar-padding-y: 0;
  --bs-navbar-color: #000;
  height: var(--navbar-height);
  transition: background 0.3s, height 0.3s;
  background: var(--color-lightgray);
}
.navbar .nav-link {
  transition: text-decoration 0.3s;
  text-decoration: underline wavy;
  text-decoration-color: transparent;
  text-underline-offset: 6px;
  transform: translateY(-3px);
}
@media (hover: hover) {
  .navbar .nav-link:hover {
    text-decoration-color: var(--bs-secondary);
  }
}
.navbar .nav-link.active {
  text-decoration-color: var(--bs-secondary);
}
.navbar .offcanvas.show .navbar-nav,
.navbar .offcanvas.showing .navbar-nav {
  --bs-nav-link-color: #fff;
  --bs-navbar-active-color: #fff;
}
.navbar .offcanvas.show .nav-link,
.navbar .offcanvas.showing .nav-link {
  --bs-nav-link-font-size: 20px;
}
.navbar .offcanvas.show .nav-link.active,
.navbar .offcanvas.showing .nav-link.active {
  text-decoration-color: #fff;
}
.navbar .navbar-brand {
  position: relative;
  display: block;
  height: 80px;
}
.navbar .navbar-brand img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: height 0.3s, top 0.3s;
  display: block;
}
.navbar .header-img-onwhite {
  opacity: 0;
}
html.transparentheader .navbar {
  --bs-navbar-color: #fff;
  --bs-navbar-active-color: #fff;
  --bs-navbar-hover-color: #fff;
  background: transparent;
}
html.is-scrolling .navbar {
  --navbar-height: 90px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
html.is-scrolling .navbar .header-img-onwhite {
  opacity: 1;
}
html.is-scrolling .navbar .navbar-brand img {
  height: 60px;
  top: 10px;
}
html.transparentheader.is-scrolling .navbar {
  --bs-navbar-color: #000;
  --bs-navbar-active-color: #000;
  --bs-navbar-hover-color: #000;
  background: #fff;
  background: var(--color-lightbrown);
  background: var(--color-lightgray);
}

html:not(.transparentheader) .navbar-brand img {
  display: none !important;
}
html:not(.transparentheader) img.header-img-onwhite {
  display: block !important;
  opacity: 1 !important;
}

.newscard {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 1.5rem;
  overflow: hidden;
  border-radius: 0.3125rem;
}

.newscard__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.newscard:before {
  display: block;
  padding-bottom: 110%;
  content: "";
}

.newscard__info {
  position: absolute;
  right: 0.9375rem;
  bottom: 0.9375rem;
  left: 0.9375rem;
  z-index: 2;
  margin-right: 0;
  font-size: 0.875rem;
  color: #fff;
}

.newscard__gradient:before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 70%;
  pointer-events: none;
  content: "";
  opacity: 0.7;
  background: -webkit-gradient(linear, left bottom, left top, from(#000), color-stop(15.6%, rgba(0, 0, 0, 0.987)), color-stop(28.9%, rgba(0, 0, 0, 0.951)), color-stop(40.3%, rgba(0, 0, 0, 0.896)), color-stop(49.8%, rgba(0, 0, 0, 0.825)), color-stop(57.7%, rgba(0, 0, 0, 0.741)), color-stop(64.3%, rgba(0, 0, 0, 0.648)), color-stop(69.7%, rgba(0, 0, 0, 0.55)), color-stop(74.3%, rgba(0, 0, 0, 0.45)), color-stop(78.2%, rgba(0, 0, 0, 0.352)), color-stop(81.6%, rgba(0, 0, 0, 0.259)), color-stop(84.8%, rgba(0, 0, 0, 0.175)), color-stop(88.1%, rgba(0, 0, 0, 0.104)), color-stop(91.5%, rgba(0, 0, 0, 0.049)), color-stop(95.4%, rgba(0, 0, 0, 0.013)), to(transparent));
  background: linear-gradient(0deg, #000, rgba(0, 0, 0, 0.987) 15.6%, rgba(0, 0, 0, 0.951) 28.9%, rgba(0, 0, 0, 0.896) 40.3%, rgba(0, 0, 0, 0.825) 49.8%, rgba(0, 0, 0, 0.741) 57.7%, rgba(0, 0, 0, 0.648) 64.3%, rgba(0, 0, 0, 0.55) 69.7%, rgba(0, 0, 0, 0.45) 74.3%, rgba(0, 0, 0, 0.352) 78.2%, rgba(0, 0, 0, 0.259) 81.6%, rgba(0, 0, 0, 0.175) 84.8%, rgba(0, 0, 0, 0.104) 88.1%, rgba(0, 0, 0, 0.049) 91.5%, rgba(0, 0, 0, 0.013) 95.4%, transparent);
}

.newscard__tag {
  display: inline-block;
  padding: 0.25rem 0.625rem;
  margin-right: 0.9375rem;
  font-size: 0.875rem;
  color: #000;
  background: #f0f8ff;
  border-radius: 0.3125rem;
}

.newscard__date {
  display: inline-block;
  font-size: 0.75rem;
}

.newscard__description {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.newscard__personal-info {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.newscard__author,
.newscard__personal-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.newscard__person-image {
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 1rem;
  overflow: hidden;
  border: 2px solid #fff;
  border-radius: 2.5rem;
}

.newscard__person-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.newscard__button {
  width: 3.5rem;
  height: 3.5rem;
  color: #fff;
  background: var(--bs-primary);
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.page-partners .gslide-description {
  display: unset;
}
html.page-partners .gslide-media.gslide-image {
  position: relative;
}
html.page-partners .gslide-description.description-bottom {
  background-color: transparent;
}
html.page-partners .gslide-description.description-bottom .gdesc-inner {
  padding: 0;
}
html.page-partners .gslide-description.description-bottom .gslide-title {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
  color: #fff;
  font-size: 14px;
  margin: 0;
}
html.page-partners #map {
  height: 280px;
}
html.page-partners .p-partnersindex .c-filters__filterblock {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
@media (min-width: 768px) {
  html.page-partners .ww-p-overview--withfilters {
    grid-template-columns: 240px 1fr;
  }
}
html.page-partners .p-partnersdetails__container {
  max-width: 900px;
}
html.page-partners .w-quote .w-quote__text-name {
  color: var(--bs-primary);
  order: 1;
}
html.page-partners .w-quote .w-quote__image {
  order: 2;
}
@media (min-width: 768px) {
  html.page-partners .w-quote {
    display: grid;
    grid-template-columns: 600px 1fr;
    gap: 3rem;
  }
  html.page-partners .w-quote .w-quote__text-name {
    max-width: unset;
  }
}
html.page-partners #partners-trigger-image-carousel-lightbox {
  display: block;
  position: relative;
  width: 100%;
  height: calc(50vh - var(--navbar-height));
}
html.page-partners #partners-trigger-image-carousel-lightbox:after {
  content: "";
  z-index: 5;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
html.page-partners #partners-trigger-image-carousel-lightbox img {
  width: 100%;
  height: 100%;
}
html.page-partners #partners-trigger-image-carousel-lightbox lottie-player {
  position: absolute;
  z-index: 15;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}
html.page-partners #partners-image-carousel {
  display: none;
}
@media (min-width: 768px) {
  html.page-partners #partners-trigger-image-carousel-lightbox {
    display: none;
  }
  html.page-partners #partners-image-carousel {
    display: unset;
  }
}
html.page-partners .c-slider__link {
  display: block;
  position: relative;
  height: 500px;
  width: 100%;
}
html.page-partners .c-slider__link .c-slider__copyright {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
  color: #fff;
  font-size: 14px;
}
html.page-partners .c-slider__link .bi {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px;
  color: var(--color-pink);
  transition: transform 0.3s;
  color: var(--bs-primary) !important;
}
html.page-partners .c-slider__link .bi::before {
  display: block;
}
@media (hover: hover) {
  html.page-partners .c-slider__link:hover .bi {
    transform: translate(-50%, -50%) scale(1.1);
  }
}
html.page-partners .c-slider__image {
  height: 100%;
  width: 100%;
}
html.page-partners .c-grid {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
html.page-partners .c-grid .c-grid__category-city {
  color: var(--color-red);
  margin-bottom: 1rem;
}
html.page-partners .c-grid .c-grid__socials-title {
  color: var(--color-lightbrown);
}
html.page-partners .c-grid .c-grid__contactlink {
  transition: color 0.3s;
}
@media (hover: hover) {
  html.page-partners .c-grid .c-grid__contactlink:hover {
    color: var(--color-lightbrown) !important;
  }
}
html.page-partners .c-grid .c-grid__socials-icon i {
  transition: color 0.3s;
}
@media (hover: hover) {
  html.page-partners .c-grid .c-grid__socials-icon i:hover {
    color: var(--color-lightbrown);
  }
}
html.page-partners .c-grid .c-grid__body {
  background: var(--color-lightgray);
}
html.page-partners .c-grid .c-grid__video iframe {
  width: 100%;
  aspect-ratio: 16/9;
}
html.page-partners .c-grid .c-grid__video-imagefallback {
  width: 100%;
  aspect-ratio: 16/9;
}
html.page-partners .c-grid .c-grid__quote,
html.page-partners .c-grid .c-grid__usps {
  padding-bottom: 3rem;
  border-bottom: 1px solid var(--color-lightbrown);
}
@media (min-width: 768px) {
  html.page-partners .c-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 4rem 0;
  }
  html.page-partners .c-grid .c-grid__body {
    grid-area: 1/1/auto/span 12;
  }
  html.page-partners .c-grid .c-grid__quote {
    grid-area: 2/1/auto/span 12;
    padding-bottom: 4rem;
  }
  html.page-partners .c-grid .c-grid__usps {
    grid-area: 3/1/auto/span 7;
    padding-right: 2rem;
    padding-bottom: 4rem;
  }
  html.page-partners .c-grid .c-grid__video {
    grid-area: 3/8/auto/span 5;
    border-bottom: 1px solid var(--color-lightbrown);
  }
  html.page-partners .c-grid .c-grid__gmaps {
    grid-area: 4/1/auto/span 6;
  }
  html.page-partners .c-grid .c-grid__contact {
    grid-area: 4/8/auto/span 5;
  }
}

html.page-partners-details main {
  margin-top: 0 !important;
}

.wh-form__fieldgroup--required label::after {
  margin-left: 5px;
  content: "*";
  color: var(--form-color-error-required-indicator);
}
.wh-form__fields {
  width: 100%;
}
.wh-form__navbuttons {
  display: flex;
  justify-content: space-between;
}
.wh-form__button--next {
  margin-left: auto;
}
.wh-form__label {
  cursor: pointer;
}
.wh-form__fieldgroup {
  margin: 0 0 16px;
}
.wh-form__checkbox, .wh-form__radio {
  transform: scale(1.3);
  margin-right: 11px;
}
.wh-form__fieldgroup--checkbox.wh-form__fieldgroup--required .wh-form__fieldline > label,
.wh-form__fieldgroup--checkbox .wh-form__optionlabel::after, .wh-form__fieldgroup--checkboxgroup.wh-form__fieldgroup--required .wh-form__fieldline > label,
.wh-form__fieldgroup--checkboxgroup .wh-form__optionlabel::after, .wh-form__fieldgroup--radiogroup.wh-form__fieldgroup--required .wh-form__fieldline > label,
.wh-form__fieldgroup--radiogroup .wh-form__optionlabel::after {
  display: none;
}
.wh-form__fieldgroup--checkbox .wh-form__textinput, .wh-form__fieldgroup--checkboxgroup .wh-form__textinput, .wh-form__fieldgroup--radiogroup .wh-form__textinput {
  margin-left: 15px;
}
.wh-form__optionlabel {
  cursor: pointer;
}
.wh-form__fieldgroup--error .wh-form__textinput,
.wh-form__fieldgroup--error .wh-form__uploadfieldfilename,
.wh-form__fieldgroup--error .wh-form__textarea,
.wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-form__fieldgroup--error .wh-form__timeinputgroup {
  border-color: var(--ww-form-color-error-border);
}
.wh-form__error {
  color: var(--ww-form-color-error-message);
}

:root {
  --bs-primary: #4f7b7a;
  --bs-primary-rgb: 79, 123, 122;
  --bs-secondary: #6c614f;
  --bs-secondary-rgb: 100, 97, 79;
  --bs-link-color-rgb: var(--bs-primary-rgb);
  --bs-body-font-family: "Cera Pro", Georgia, "Times New Roman", serif;
  --bs-body-font-size: 1rem;
  --navbar-height: 120px;
  --navbar-mb: 32px;
  --color-red: #e14f63;
  --color-lightgray: #f7f4f1;
  --color-lightbrown: #cbbba0;
  --ww-widget-textvideo-background-color: var(--color-lightgray);
  --ww-widget-textvideo-text-color: #000;
  --ww-footer-bg-color: var(--color-lightgray);
  --ww-footer-heading-color: #000;
  --ww-footer-text-color: #000;
}

.ww-w-textvideo__text b {
  display: inline-block;
}

a.text-primary {
  transition: color 0.3s;
}
@media (hover: hover) {
  a.text-primary:hover {
    color: var(--color-lightbrown) !important;
  }
}

.navbar {
  --bs-navbar-toggler-focus-width: 0;
}

.navbar-nav {
  --bs-navbar-color: #000;
  --bs-navbar-active-color: var(--bs-navbar-color);
}

html.transparentheader .navbar-nav {
  --bs-navbar-color: #fff;
}

html:not(.transparentheader) .navbar-nav,
html.is-scrolling .navbar-nav {
  --bs-navbar-color: var(--bs-primary);
}

.glightbox-clean .gnext,
.glightbox-clean .gprev {
  top: 45%;
  background-color: rgba(0, 0, 0, 0.32);
}

.ww-p-overview--withfilters {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  @layer setupPage, markers;
}
.ww-p-overview--withfilters .card {
  height: 100%;
}
.ww-p-overview--withfilters .card-body--link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.ww-p-overview--withfilters .c-filters__filtertitle {
  font-weight: 500;
}
.ww-p-overview--withfilters .c-filters__filterblock {
  margin-bottom: 0.5rem;
}
.ww-p-overview--withfilters .c-filters__filterblock .wh-form__fieldgroup--checkboxgroup {
  margin-top: 4px;
  margin-left: 4px;
}
@media (min-width: 768px) {
  .ww-p-overview--withfilters .c-filters__filterblock {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 768px) {
  .ww-p-overview--withfilters .c-filters {
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .ww-p-overview--withfilters .c-filters__filtertitle::marker {
    display: none;
    font-size: 0;
  }
  .ww-p-overview--withfilters .c-filters__filterblock {
    margin-bottom: 0;
  }
  .ww-p-overview--withfilters .wh-form__fieldgroup.wh-form__fieldgroup--checkboxgroup {
    margin-bottom: 0;
  }
}
.ww-p-overview--withfilters details {
  border-radius: 0.5rem;
  margin: 1rem;
  transition: 50ms;
  margin: 0;
  padding-bottom: 0 !important;
}
.ww-p-overview--withfilters details[open] {
  padding-bottom: 1em;
}
.ww-p-overview--withfilters summary::before,
.ww-p-overview--withfilters summary::after {
  transition: 200ms;
}
@layer markers {
  .ww-p-overview--withfilters summary {
    position: relative;
  }
  .ww-p-overview--withfilters summary::-webkit-details-marker {
    display: none;
  }
  .ww-p-overview--withfilters summary::marker {
    content: none;
  }
  .ww-p-overview--withfilters summary::before,
  .ww-p-overview--withfilters summary::after {
    content: "";
  }
  .ww-p-overview--withfilters summary::before,
  .ww-p-overview--withfilters summary::after {
    width: 0.75em;
    height: 0;
    border-bottom: 2px solid;
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    transform: translateY(-50%);
  }
  .ww-p-overview--withfilters summary::after {
    transform: rotate(90deg);
    transform-origin: 50% 50%;
  }
  .ww-p-overview--withfilters [open] summary::after {
    transform: rotate(0deg);
  }
}

.ww-p-overview__items {
  display: grid;
  gap: 2rem 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
}
.ww-p-overview__items .card-img-top {
  aspect-ratio: 4/3;
}

@media (min-width: 768px) {
  .ww-p-overview--withfilters {
    grid-template-columns: 350px 1fr;
  }
  .ww-p-overview__filters {
    border-right: 1px solid var(--color-lightbrown);
    margin-right: 1rem;
  }
}
.offcanvas {
  background-color: var(--bs-primary) !important;
}

.offcanvas-title__img {
  display: block;
  height: 65px;
  width: auto;
}

.offcanvas .navbar-nav {
  --bs-navbar-color: #fff;
}

html.transparentheader:not(.is-scrolling) .navbar-toggler {
  border-color: #fff;
}
html.transparentheader:not(.is-scrolling) .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.icon-link {
  display: flex;
  align-items: center;
}
.icon-link .bi::before {
  display: block;
}

.fs-7 {
  font-size: 0.75rem !important;
}

body {
  padding-top: var(--navbar-height);
}

html.haspageloader.init body {
  opacity: 1;
  pointer-events: auto;
}

html:not(.transparentheader) main {
  margin-top: 3rem;
}

html.nocontainer main > .container-xl, html.nocontainer main > .ww-grid, html.nocontainer main.wh-rtd > h1,
html.nocontainer main.wh-rtd > h2,
html.nocontainer main.wh-rtd > h3,
html.nocontainer main.wh-rtd > p,
html.nocontainer main.wh-rtd > ul,
html.nocontainer main.wh-rtd > ol,
html.nocontainer .wh-rtd > main.unordered > .wh-form,
html.nocontainer main.wh-rtd > .widget--ctas,
html.nocontainer main.wh-rtd > .widget--threecolumns {
  max-width: initial;
  padding: 0;
}

.container-xl, .ww-grid, .wh-rtd > h1,
.wh-rtd > h2,
.wh-rtd > h3,
.wh-rtd > p,
.wh-rtd > ul,
.wh-rtd > ol,
.wh-rtd > .unordered > .wh-form,
.wh-rtd > .widget--ctas,
.wh-rtd > .widget--threecolumns {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 1200px) {
  .container-xl, .ww-grid, .wh-rtd > h1,
  .wh-rtd > h2,
  .wh-rtd > h3,
  .wh-rtd > p,
  .wh-rtd > ul,
  .wh-rtd > ol,
  .wh-rtd > .unordered > .wh-form,
  .wh-rtd > .widget--ctas,
  .wh-rtd > .widget--threecolumns {
    max-width: 1140px;
  }
}

.wh-rtd > ul,
.wh-rtd > ol {
  padding-left: 2em;
}

.wh-rtd__tablewrap.table {
  overflow-x: auto;
}

ul.unordered_hearts {
  padding-left: 0;
  margin-bottom: 0;
}
ul.unordered_hearts li {
  margin-left: 1em;
  background: no-repeat url(~@mod-tit/webdesigns/tit/web/img/heart.svg);
  background-size: 24px;
  padding-left: 0;
  list-style: none;
  margin: 0 0 20px 0;
  padding-left: 40px;
}
ul.unordered_hearts li:last-child {
  margin-bottom: 0;
}

.w-photoalbum__info {
  display: none;
}

.widget--photoalbum.w-photoalbum--variant-grid_portrait .w-photoalbum__items--grid .w-photoalbum__photocontainer {
  padding-bottom: 0;
  height: auto;
  aspect-ratio: 3/4;
}

.w-ctas__blocktitle {
  font-family: "Sacramento", cursive;
  font-size: 48px;
  line-height: 100%;
  text-align: center;
  margin-bottom: 1rem;
  color: var(--bs-primary);
}