html.page-blog-details .section-gradient a {
  color: var(--color-primary);
}

.p-blogdetails {
  max-width: 800px;
  margin: 4rem auto 0;
}

.p-blogdetails__title {
  margin: 0 0 2rem;
  font-size: var(--h3-font-size);
  line-height: 37px;
}

.p-blogdetails__date,
.p-blogdetails__blogger {
  font-size: 12px;
  line-height: 16px;
  color: var(--color-gray);
  margin: 0 0 8px;
}

.p-blogdetails__blogger {
  text-align: right;
}

.p-blogdetails__image {
  margin: 0 0 0.5rem;
}
.p-blogdetails__image img {
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.p-blogindex__cards {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  margin-top: 1rem !important;
}
.p-blogindex__cards .newscard__description {
  font-size: 17px;
  line-height: 130%;
  font-weight: 400;
}
.p-blogindex__cards .newscard__img {
  transition: transform 0.3s;
}
.p-blogindex__cards .newscard {
  width: auto;
  height: auto;
  display: block;
  aspect-ratio: 1;
}
@media (hover: hover) {
  .p-blogindex__cards .newscard:hover .newscard__img {
    transform: scale(1.1);
  }
}