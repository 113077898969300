/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-selectcategory {
  display: block;
  width: 80vw;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  background-color: var(--color-lightgray);
}
@media (max-width: 991.98px) {
  .w-selectcategory {
    width: 100vw;
    max-width: unset;
  }
}

.w-selectcategory__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 1.5rem;
}
.w-selectcategory__list img {
  height: 90px;
}

.w-selectcategory__item {
  background: #fff;
  font-family: "cera-pro-medium", Georgia, "Times New Roman", serif;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--bs-primary);
  font-size: 14px;
  line-height: 100%;
  transition: background 0.3s;
}
@media (hover: hover) {
  .w-selectcategory__item:hover {
    background-color: #ebebeb !important;
  }
}

.w-selectcategory__title {
  font-family: "Sacramento", cursive;
  font-size: 48px;
  line-height: 100%;
}