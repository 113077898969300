/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-quote {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  text-align: center;
}

.w-quote__text-name {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.w-quote__image {
  display: block;
  width: 100px;
  aspect-ratio: 1/1;
  border-radius: 100%;
}

.w-quote__name {
  color: var(--bs-primary);
  font-size: 18px;
  line-height: 100%;
}

@media (min-width: 768px) {
  .w-quote {
    flex-direction: row;
    gap: 32px;
    text-align: left;
  }
  .w-quote__text-name {
    order: 1;
    max-width: 650px;
  }
  .w-quote__image {
    width: 150px;
  }
}