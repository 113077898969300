/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
html.page-category .p-category__backlink {
  display: inline-block;
  margin-bottom: 2rem;
}
html.page-category .p-category__detailstextcontainer {
  border-top: 1px solid var(--color-lightbrown);
  margin-top: 2rem;
  padding-top: 1rem;
}
html.page-category .p-category__detailstextcontainer img {
  height: 80px;
}
@media (min-width: 768px) {
  html.page-category .p-category__detailstextcontainer {
    margin: 0 auto;
    max-width: 900px;
    margin-top: 4rem;
  }
}
html.page-category .p-category__detailstext > *:first-child {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
html.page-category .c-categories {
  margin: 24px 0;
}
html.page-category .c-categories .splide__slide img {
  width: 100%;
  height: auto;
}
html.page-category .c-categories .splide__slide {
  width: 200px;
}
html.page-category .c-categories .splide__slide a {
  color: #547e7d;
}
html.page-category .c-categories .splide__slide a {
  text-decoration: none;
}
html.page-category .c-categories .splide__slide.c-categories__selectedcategory {
  opacity: 1;
  border: 0 !important;
}
html.page-category .c-categories .splide__slide.c-categories__selectedcategory a {
  color: var(--color-red);
}
html.page-category .c-categories .splide__arrow--prev,
html.page-category .c-categories .splide__arrow--next {
  transform: translateY(-100%);
  top: 0;
}
html.page-category .c-categories .splide__arrow--prev {
  left: -3rem;
  left: 0;
}
html.page-category .c-categories .splide__arrow--next {
  right: -3rem;
  right: 0;
}
html.page-category .c-categories__title {
  font-size: 14px;
}

.card__imagecontainer {
  position: relative;
}
.card__imagecontainer .card__copyrighttext {
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
  color: #fff;
  font-size: 14px;
}